<!-- Localized -->
<template>
<div class="sidelegend">
    <div
        :class="['sidelegend__button', {
            'sidelegend__button--opened': isOpened,
        }]"
        @click="toggle"
    >
        <svg v-if="isOpened" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#fff">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
        </svg>
        <svg v-else width="36" height="36" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21 0C9.4033 0 0 9.4033 0 21C0 32.5967 9.4033 42 21 42C22.9367 42 24.5 40.4367 24.5 38.5C24.5 37.59 24.1617 36.7733 23.59 36.155C23.0417 35.5367 22.715 34.7317 22.715 33.8333C22.715 31.8966 24.2783 30.3333 26.215 30.3333H30.3333C36.7733 30.3333 42 25.1066 42 18.6666C42 8.3533 32.5967 0 21 0ZM8.1667 21C6.23 21 4.6667 19.4367 4.6667 17.5C4.6667 15.5633 6.23 14 8.1667 14C10.1034 14 11.6667 15.5633 11.6667 17.5C11.6667 19.4367 10.1033 21 8.1667 21ZM15.1667 11.6667C13.23 11.6667 11.6667 10.1034 11.6667 8.1667C11.6667 6.23 13.23 4.6667 15.1667 4.6667C17.1034 4.6667 18.6667 6.23 18.6667 8.1667C18.6667 10.1034 17.1033 11.6667 15.1667 11.6667ZM26.8333 11.6667C24.8966 11.6667 23.3333 10.1034 23.3333 8.1667C23.3333 6.23 24.8966 4.6667 26.8333 4.6667C28.77 4.6667 30.3333 6.23 30.3333 8.1667C30.3333 10.1034 28.77 11.6667 26.8333 11.6667ZM33.8333 21C31.8966 21 30.3333 19.4367 30.3333 17.5C30.3333 15.5633 31.8966 14 33.8333 14C35.77 14 37.3333 15.5633 37.3333 17.5C37.3333 19.4367 35.77 21 33.8333 21Z" fill="white"/>
        </svg>
    </div>
    <div
        :class="['sidelegend__content', {
            'sidelegend__content--opened': isOpened,
        }]"
    >
        <ul class="list">
            <li
                class="list__item  w-full"
                v-for="item in list"
                :key="item.label"
            >
                <div
                    class="item__icon shadow-lg"
                    :style="{'background-color': item.color}"
                ></div>
                <div class="item__label select-none">{{item.label}}</div>
            </li>
        </ul>
    </div>
</div>
</template>

<script>
export default {
    name: 'sidelegend',
    model: {
        prop: 'isOpened',
        event: 'change'
    },
    props: {
        list: {
            type: Array,
            default: () => [],
        },
        isOpened: {
            type: Boolean,
            default: false,
        }
    },
    methods: {
        toggle() {
            this.$emit('change', !this.isOpened);
        },
    },
};
</script>

<style lang="scss" scoped>
.sidelegend {
    position: fixed;
    transform: translateY(-50%);
    top: 45%;
    z-index: 99;
    right: 0;
    overflow: hidden;
    &__button {
        background-color: #3C4B52;
        border-radius: 50% 0 0 50%;
        display: flex;
        align-items: center;
        z-index: 1;
        cursor: pointer;
        padding: 16px;
        transition: .25s ease-in;
        svg {
            // height: 42px;
                height: 24px;
                width: 24px;
            // transition: transform .2s;
        }
        &:hover {
            background: lighten(#3C4B52,10%)
        }
        &--opened {
            @apply bg-primary;
            padding: 0;
            height: 36px;
            width: 36px;
            transform: translate(50%, -50%);
            position: absolute;
            top: 50%;
            right: 0;
            svg {
                height: 24px;
                transform: scaleX(-1);
            }
        }
    }
    &__content {
        padding: 24px;
        transform: translateX(110%);
        transition: transform .2s;
        background-color: #3C4B52;
        border-top-left-radius: 24px;
        border-bottom-left-radius: 24px;
        color: #fff;
        display: none;
        &--opened {
            display: block;
            transform: translateX(0);
        }
        .list {
            margin: 0;
            padding: 0;
            font-size: 16px;
            letter-spacing: 0.05em;
            font-weight: 500;
            line-height: 24px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 24px;
            &__item {
                display: flex;
                align-items: center;
                gap: 10px;
                .item {
                    &__icon {
                        width: 24px;
                        height: 24px;
                        border-radius: 8px;
                    }
                }
            }
        }
    }
}
</style>